<template>
  <div class="main">
    <el-form label-position="left" :model="verifyloginForm" v-if="!success">
      <el-form-item label="手机号">
        <el-input
          v-model="verifyloginForm.mobile"
          placeholder="手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="验证码">
        <el-input v-model="verifyloginForm.code" placeholder="验证码">
          <el-button :disabled="isDisabled" slot="append" @click="getCode()">{{
            buttonName
          }}</el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即绑定</el-button>
      </el-form-item>
    </el-form>
    <div v-else style="text-align: center; margin-top: 100px">
      <i class="el-icon-success" style="color: green; font-size:68px"></i>
      <p>已成功绑定</p>
    </div>
  </div>
</template>

<script>
import { getMobileCode, checkMobileInSys, wechatBind } from "@/api/user";
export default {
  data() {
    return {
      verifyloginForm: {
        mobile: "",
        code: "",
      },
      checkKey: null,
      isDisabled: false,
      buttonName: "获取验证码",
      second: 60,
      query: {},
      success: false
    };
  },
  mounted() {
    this.query = this.$route.query;
    if (this.query.type == "03") {
      this.$message({
        type: 'error',
        message: '获取微信信息错误，请关闭页面重试！'
      })
    } else if(this.query.type == "02") {
      this.success = true;
    }
  },
  methods: {
    // 获取手机验证码
    getCode() {
      // 校验手机号码是否为空
      if (this.verifyloginForm.mobile == "") {
        this.$message.error("请输入手机号码！");
        return false;
      }
      this.checkMobileInSys(this.verifyloginForm.mobile).then((boo) => {
        if (boo) {
          this.run();
          getMobileCode(this.verifyloginForm.mobile).then((res) => {
            if (res.success) {
              this.checkKey = res.data;
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          // 提示系统内不存在该用户，转去注册
          this.$message({
            type: "warning",
            message: "系统内不存在该用户，请先注册！",
          });
        }
      });
    },
    // 校验手机是否有账号
    checkMobileInSys(mobile) {
      return new Promise((resolve) => {
        checkMobileInSys(mobile).then((res) => {
          if (res.success) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
    // 倒计时
    run() {
      let that = this;
      that.isDisabled = true;
      let interval = window.setInterval(function () {
        that.buttonName = "(" + that.second + "秒)后重新发送";
        --that.second;
        if (that.second < 0) {
          that.buttonName = "重新发送";
          that.second = 60;
          that.isDisabled = false;
          window.clearInterval(interval);
        }
      }, 1000);
    },
    // 公众号绑定
    wechatBind(data) {
      wechatBind(data).then(res => {
        if (res.success) {
          this.success = true;
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    onSubmit() {
      // 校验验证码是否为空
      if (this.verifyloginForm.code == "") {
        this.$message.error("请输入验证码！");
        return false;
      }
      this.wechatBind({
        ...this.verifyloginForm,
        ticket: this.query.ticket,
        checkKey: this.checkKey
      })
    },
  },
};
</script>

<style lang="scss" scope>
.main {
  padding: 20px;
}
</style>